<template>
    <div class="container full">
        <!-- Icon -->
        <div class="half center" style="gap: 2rem">
            <div>
                <div v-glitch="glitchOption">
                    <img
                        :src="require('./assets/logo.png')"
                        alt="logo"
                        class="logo center"
                    />
                </div>
                <h1
                    v-glitch="glitchOption"
                    class="center"
                    style="margin-top: 1rem"
                >
                    Hey, I'm
                    <span class="highlight" style="white-space: nowrap"
                        >Addison Chua</span
                    >
                </h1>
                <p v-glitch="glitchOption" class="center subtle">
                    Full Stack Dev | Audio Engineer | Video Editor | Synflux
                </p>
            </div>

            <div class="landing-links">
                <a
                    href="https://github.com/NotAddison"
                    class="hover"
                    target="_blank"
                    ><img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/fluency-systems-regular/50/FFFFFF/code-fork.png"
                        v-tooltip="'Github'"
                        alt="GitHub"
                /></a>
                <a href="https://bit.ly/LoneOxide" class="hover" target="_blank"
                    ><img
                        width="50"
                        height="50"
                        :src="require('./assets/images/loneoxide.png')"
                        v-tooltip="'Muisc - LoneOxide'"
                        alt="LoneOxide"
                /></a>
                <a href="https://synflux.tech" class="hover" target="_blank"
                    ><img
                        width="50"
                        height="50"
                        :src="require('./assets/images/synflux.png')"
                        v-tooltip="'Synflux - Tech Startup'"
                        alt="Synflux"
                /></a>
                <a
                    href="https://www.linkedin.com/in/addison-chua/"
                    class="hover"
                    target="_blank"
                    ><img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/fluency-systems-regular/50/FFFFFF/linkedin.png"
                        v-tooltip="'LinkedIn'"
                        alt="LinkedIn"
                /></a>
                <a href="mailto:add@synflux.tech" class="hover"
                    ><img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/fluency-systems-regular/50/FFFFFF/new-post.png"
                        alt="message"
                        v-tooltip="'Contact'"
                /></a>

                <a
                    href="#"
                    class="hover"
                    @click="ShowToast('In Development...')"
                >
                    <img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/fluency-systems-regular/50/FFFFFF/resume.png"
                        alt="message"
                        v-tooltip="'Portfolio (PDF)'"
                    />
                </a>
            </div>
        </div>

        <!-- Profile Pic -->
        <atropos-component
            class="half center mobile-hidden"
            highlight="false"
            style="width: 100%"
        >
            <div data-atropos-offset="0" v-glitch="autoGlitchOption">
                <img
                    :src="require('./assets/images/glitched.png')"
                    data-atropos-offset="1"
                    style="border-radius: 1rem"
                />
                <!-- <img
                    :src="require('./assets/images/me.png')"
                    data-atropos-offset="4"
                    style="position: absolute; left: 0"
                /> -->
                <!-- Censor Bar -->
                <p class="subtle" data-atropos-offset="5">
                    Hello <span style="color: rgb(199, 219, 21)">{{ ip }}</span>
                </p>
            </div>
        </atropos-component>
    </div>

    <hr />

    <!-- Code Stats -->
    <div class="half card">
        <Stats />
    </div>

    <hr />

    <!-- Projects & Experiences -->
    <div class="container card">
        <Experience />
    </div>

    <hr />

    <!-- Education -->
    <div class="container card">
        <Education />
    </div>
</template>

<script>
// Import stats.vue
import Stats from "./components/Stats.vue";
import Education from "./components/Education.vue";
import Experience from "./components/Experience.vue";

// Import Atropos
import AtroposComponent from "atropos/element";
customElements.define("atropos-component", AtroposComponent);

// External Functions
import { GetGlitchSettings } from "@/functions/variables";
import { CheckAPI } from "@/functions/api-check";
import { ShowToast } from "@/functions/toast";

export default {
    name: "App",
    components: {
        Stats,
        Education,
        Experience,
    },
    data: () => ({
        ip: "world",
        glitchOption: GetGlitchSettings(),
        autoGlitchOption: GetGlitchSettings(),
        // picGlitch: {...GetGlitchSettings(),
        //   'timing.iteration': 100,
        //   'timing.delay': 5000,
        // }
    }),
    created() {
        CheckAPI();
    },
    methods: {
        ShowToast,
    },
};
</script>

<style>
.test {
    border: 1px dotted white;
}

.full {
    height: 80vh;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.landing-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 0.5rem 2rem;
    margin-top: 1rem;

    min-height: 3rem;

    /* Corner Borders */
    --b: 2px; /* thickness of the border */
    --c: rgb(233, 233, 233); /* color of the border */
    --w: 20px; /* width of border */
    border: var(--b) solid #0000; /* space for the border */
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(
                from 90deg at top var(--b) left var(--b),
                var(--_g)
            )
            0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g))
            100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
            100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
            100% 100% / var(--_p);

    background-color: rgba(10, 10, 10, 0.37);
    box-shadow: 0 8px 32px 0 rgba(7, 7, 7, 0.801);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(2px);
}

.hover {
    transition: 0.1s;
}

.hover:hover {
    transform: scale(1.02);
}

.logo {
    width: 20rem;
    height: auto;
}

.card {
    /* background-color: rgb(14, 14, 14); */
    border-radius: 0.5rem;
    padding: 1.5rem;

    background: rgba(12, 12, 12, 0.295);
    box-shadow: 0 8px 32px 0 rgba(7, 7, 7, 0.801);
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(10px);
}

.center {
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.row {
    flex-direction: row !important;
}

.half {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-basis: 47%;
}

.glass {
    background-color: rgba(17, 17, 17, 0.1);
    backdrop-filter: blur(10px);
}

.space-between {
    justify-content: space-between;
}

.indent {
    border-left: 0.3rem solid #303030;
    padding-left: 0.5rem;
    margin-top: 0.5rem;
}

.subtle {
    opacity: 0.5;
    font-size: 0.8rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .card {
        margin: 3rem 0;
    }

    .half {
        flex-basis: 100%;
        margin-top: 1rem;
    }

    .logo {
        width: 50%;
    }

    .landing-links {
        justify-content: space-evenly;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .mobile-hidden {
        display: none;
    }
}
</style>
